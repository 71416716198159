import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex align-items-stretch flex-shrink-0" }
const _hoisted_2 = { class: "d-flex align-items-center ms-1 ms-lg-3" }
const _hoisted_3 = {
  class: "btn btn-icon btn-active-light-primary position-relative w-30px h-30px w-md-40px h-md-40px",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-attach": "parent",
  "data-kt-menu-placement": "bottom-end",
  "data-kt-menu-flip": "bottom"
}
const _hoisted_4 = { class: "svg-icon svg-icon-1" }
const _hoisted_5 = {
  id: "kt_header_user_menu_toggle",
  class: "d-flex align-items-center ms-1 ms-lg-3"
}
const _hoisted_6 = {
  class: "cursor-pointer symbol symbol-30px symbol-md-40px",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-attach": "parent",
  "data-kt-menu-placement": "bottom-end",
  "data-kt-menu-flip": "bottom"
}
const _hoisted_7 = ["src"]
const _hoisted_8 = {
  id: "kt_header_language_menu_toggle",
  class: "d-flex align-items-center ms-1 ms-lg-3"
}
const _hoisted_9 = {
  class: "cursor-pointer symbol symbol-30px symbol-md-40px",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-attach": "parent",
  "data-kt-menu-placement": "bottom-end",
  "data-kt-menu-flip": "bottom"
}
const _hoisted_10 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_KTNotificationsMenu = _resolveComponent("KTNotificationsMenu")!
  const _component_KTUserMenu = _resolveComponent("KTUserMenu")!
  const _component_KtLanguageMenu = _resolveComponent("KtLanguageMenu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, [
          _createVNode(_component_inline_svg, {
            src: _ctx.sita_path + '/media/icons/duotune/general/gen022.svg'
          }, null, 8, ["src"])
        ])
      ]),
      _createVNode(_component_KTNotificationsMenu)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("img", {
          src: _ctx.sita_path + '/media/avatars/300-1.jpg',
          alt: "metronic"
        }, null, 8, _hoisted_7)
      ]),
      _createVNode(_component_KTUserMenu)
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("img", {
          class: "rounded-1 ms-2",
          src: _ctx.sita_path + '/' + _ctx.currentLangugeLocale.flag,
          alt: "metronic",
          style: {"width":"20px","height":"20px"}
        }, null, 8, _hoisted_10),
        _createTextVNode(" " + _toDisplayString(_ctx.currentLangugeLocale.name), 1)
      ]),
      _createVNode(_component_KtLanguageMenu)
    ])
  ]))
}